<template>
  <div>     
    <ul class="list-group mb-3">
      <li
        v-for="item in items"
        :key="item.id"
        class="list-group-item lh-condensed"
      >
        <b-row>
          <b-col class="d-flex flex-column align-items-start" md="7">
            <h6 class="my-0">
              {{ item.description }}
            </h6>
            <small class="text-muted">{{
              get_product_parcel(item.sequence)
            }}</small>
          </b-col>
          <b-col class="d-flex flex-column align-items-end justify-content-center">
            <span class="text-muted"> {{ toMonetary(item.value) }}</span>           
          </b-col>
        </b-row>
      </li>
      <li class="list-group-item d-flex justify-content-between">
        <span>Total</span>
        <strong>{{ toMonetary(invoice[0].total) }}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["items", "invoice"],
  methods: {
    get_product_parcel(item) {
      if (item) {
        const [actual, total] = item.split("/");
        return `Parcela ${actual} de ${total}`;
      } else {
        return ''
      }
    },
  },
};
</script>

<style>
</style>